<template>
  <div class="dialog">
    <el-upload
      class="upload-demo"
      ref="elupload"
      action=""
      :accept="'image/*'"
      multiple
      :file-list="showFileList"
      list-type="picture-card"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      :http-request="httpRequest"
      :on-exceed="onExceed"
      :on-success="handleSuccess"
      :auto-upload="autoUpload"
      :on-error="handlEerror"
      :limit="limit"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- 查看大图 -->
    <el-dialog :visible.sync="imgVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import oss from '@/utils/oss';

export default {
  name: 'LUploadImg',
  props: {
    autoUpload: {
      type: Boolean,
      default: true,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 999999,
    },
    folderName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgVisible: false,
      dialogImageUrl: '',
      localFileList: [],
    };
  },
  computed: {
    showFileList: {
      get() {
        const client = oss.client();
        const imgArr = this.fileUrl ? this.fileUrl.split(',') : [];
        const imgUrlArr = imgArr.map(val => {
          const remoteUrl = client.signatureUrl(val);
          const obj = {
            name: val,
            url: remoteUrl,
          };
          return obj;
        });
        return imgUrlArr;
      },
      set(val) {
        console.log('set');
        return val;
      },
    },
    folderNames() {
      // 去除开头和结尾的 '/' 字符
      const folderName = this.folderName.replace(/(^\/+)|(\/+$)/g, '');
      return folderName;
    },
  },
  methods: {
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error('文件大小不能超过20M！');
        return false;
      }
      return true;
    },
    httpRequest(option) {
      const { file } = option;
      const client = oss.client();
      const myDate = new Date();
      const year = myDate.getFullYear();
      const month =
        myDate.getMonth() > 8
          ? `${myDate.getMonth() + 1}`
          : `0${myDate.getMonth() + 1}`;
      const day = myDate.getDate();
      const loading = this.$loading({
        lock: true,
        text: '上传中，请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const arr = file.name.split('.');
      arr.splice(arr.length - 1, 0, myDate.valueOf());

      client
        .put(
          `iya/${this.folderNames}/${year}${month}${day}/${arr.join('.')}`,
          file,
        )
        .then(res => {
          option.onSuccess(res);
        })
        .catch(err => {
          option.onError(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    handlEerror() {
      this.clearFiles();
      this.$message.error('上传失败，请重试！');
    },

    handleSuccess(response, file, fileList) {
      this.$message.success('上传成功！');
      // const { name, url } = response;
      // const obj = {
      //   name,
      //   url,
      // };
      // const addList = this.showFileList;
      // addList.push(obj);
      // console.log(addList);

      const list = fileList.map(item => {
        const newItem = {
          name: item?.response?.name ?? item.name,
          url: item?.response?.url ?? item.url,
        };
        return newItem;
      });
      this.$emit('getFile', list);
      this.dialogVisible = false;
    },
    handleRemove(file, fileList) {
      const remList = [];
      fileList.forEach(val => {
        const obj = {
          name: val.response ? val.response.name : val.name,
          url: val.response ? val.response.url : val.url,
        };
        remList.push(obj);
      });
      this.$emit('getFile', remList);
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.imgVisible = true;
    },
    onExceed() {
      this.errorBox(this, '只能上传', this.showFileList.length, '张图片！');
    },
    // 手动上传
    submit() {
      this.$refs.elupload.submit();
    },
    // 清除文件
    clearFiles() {
      this.$refs.elupload.clearFiles();
      this.changeFileUrl();
    },
    // 改变FileUrl值
    changeFileUrl() {
      // this.$emit('update:fileUrl', data);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-upload-list__item {
  transition: none !important;
}
</style>
