<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="800px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="题目设置：" prop="questionDescription">
              <el-input
                placeholder="请输入题目名称"
                v-model.trim="formData.questionDescription"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="题目说明：" prop="questionExplain">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
                placeholder="请输入题目说明"
                v-model.trim="formData.questionExplain"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片：" prop="files">
              <l-upload-img
                ref="LUploadImg"
                :fileUrl="formData.files"
                :folderName="'valueation_questions'"
                @getFile="getFile"
              ></l-upload-img>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否首题：" prop="isFirstQuestion">
              <el-switch
                v-model="formData.isFirstQuestion"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="此题必答：" prop="isMustAnswer">
              <el-switch
                v-model="formData.isMustAnswer"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="题型：" prop="questionType">
              <el-radio-group v-model="formData.questionType">
                <el-radio :label="1">单选</el-radio>
                <el-radio :label="2">多选</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="`${formData.questionType}` === '2'">
            <el-form-item label="可选范围：" label-width="100px">
              <el-col :span="6">
                <el-form-item
                  label="最少"
                  label-width="50px"
                  prop="choiceRangeMin"
                >
                  <el-input-number
                    v-model="formData.choiceRangeMin"
                    :min="1"
                    :max="formData.choiceRangeMax"
                    :step="1"
                    step-strictly
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="最多"
                  label-width="80px"
                  prop="choiceRangeMax"
                >
                  <el-input-number
                    v-model="formData.choiceRangeMax"
                    :min="formData.choiceRangeMin"
                    :max="50"
                    :step="1"
                    step-strictly
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col
            v-for="(item, index) in formData.questionOptionList"
            :key="index"
            class="desItem"
          >
            <el-col :span="19">
              <el-form-item
                :label="`${index + 1}、选项设置：`"
                label-width="120px"
                :prop="`questionOptionList[${index}].optionDescription`"
                :rules="[
                  {
                    required: true,
                    message: '请设置选项名称',
                    trigger: 'change',
                  },
                ]"
              >
                <el-input
                  placeholder="请设置选项名称"
                  v-model="item.optionDescription"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" :push="1">
              <el-col :span="12">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-s-tools"
                  circle
                  @click="setQuestion(index)"
                ></el-button>
              </el-col>
              <el-col :span="12">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  circle
                  @click="delQuestion(index)"
                ></el-button>
              </el-col>
            </el-col>
          </el-col>
          <el-col>
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="addOption()"
              >添加单个选项
            </el-button>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!-- 设置选项 -->
    <set-option
      ref="SetOption"
      :questionList="questionList"
      @getOptionInfo="getOptionInfo"
    ></set-option>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SetOption from './SetOption.vue';
import LUploadImg from '@/components/LUploadImg.vue';
import api from '@/api/ValuationItemApi';

export default {
  name: 'SetQuestion',
  components: {
    SetOption,
    LUploadImg,
  },
  props: {
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dialogTitle: '添加问题设置',
      formData: {
        productId: '',
        questionDescription: '',
        questionExplain: '',
        isFirstQuestion: 1,
        isMustAnswer: 1,
        questionType: 1,
        choiceRangeMin: 1,
        choiceRangeMax: 1,
        files: '',
        attachmentInfoList: [],
        questionOptionList: [
          {
            optionDescription: '',
          },
        ],
      },
      rules: {
        questionDescription: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  created() {
    const { id } = this.$route.params;
    this.formData.productId = id;
  },
  methods: {
    setQuestion(i) {
      this.$refs.SetOption.dialogVisible = true;
      this.$refs.SetOption.quesIndex = i;
      // const keys = Object.keys(this.formData.questionOptionList[i]).map(key => key);
      this.$nextTick(() => {
        // if (keys.includes('impactBasePrice')) {
        Object.assign(
          this.$refs.SetOption.formData,
          this.formData.questionOptionList[i],
        );
        // this.$refs.SetOption.formData = {
        //   ...this.formData.questionOptionList[i],
        // };
        // }
      });
    },
    delQuestion(i) {
      if (this.formData.questionOptionList.length === 1) {
        return;
      }
      this.$confirm('确定删除该条数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            if (this.formData.questionOptionList[i].id) {
              api
              .deleteQuestionOption(this.formData.questionOptionList[i].id)
              .finally(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              });
            } else {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
          this.formData.questionOptionList.splice(i, 1);
        })
        .catch(() => {});
    },
    addOption() {
      const obj = {
        optionDescription: '',
      };
      this.formData.questionOptionList.push(obj);
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          const pass = this.formData.questionOptionList.every((val, i) => {
            const keys = Object.keys(val).map(key => key);
            if (!keys.includes('impactBasePrice')) {
              this.errorBox(
                this,
                '第',
                `${i + 1}`,
                '个选项未设置，请点击右侧设置按钮进行设置',
              );
            }
            return keys.includes('impactBasePrice');
          });
          if (pass) {
            if (this.formData.questionOptionList.length < 2) {
              this.$message.error('至少设置2个选项');
              return;
            }
            this.loading = true;
            if (this.dialogTitle === '添加问题设置') {
              api
                .addQuestionProject({
                  ...this.formData,
                  creatorId: this.userInfo.userId,
                  creatorName: this.userInfo.userName,
                })
                .then(res => {
                  this.dialogVisible = false;
                  this.$message.success(res.message);
                  this.$emit('refreshTable');
                })
                .catch(err => {
                  console.log(err);
                  this.$message.error(err.message);
                })
                .finally(() => {
                  this.loading = false;
                });
            } else {
              api
                .modifyQuestionProject({
                  ...this.formData,
                  creatorId: this.userInfo.userId,
                  creatorName: this.userInfo.userName,
                })
                .then(res => {
                  this.dialogVisible = false;
                  this.$message.success(res.message);
                  this.$emit('refreshTable');
                })
                .catch(err => {
                  console.log(err);
                  this.$message.error(err.message);
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          }
        }
      });
    },
    getOptionInfo(form, i) {
      Object.keys(form).forEach(key => {
        this.formData.questionOptionList[i][key] = form[key];
      });
    },
    getFile(files) {
      const attachmentInfoList = files.map(val => {
        const obj = {
          attachmentName: val.name.split('/').reverse()[0],
          attachmentPath: val.name,
          attachmentType: 1,
        };
        return obj;
      });
      this.formData.attachmentInfoList = attachmentInfoList;

      const arr = files.map(val => val.name);
      this.formData.files = arr.join(',');
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
      this.formData.attachmentInfoList = [];
      this.formData.questionOptionList = [
        {
          optionDescription: '',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.desItem {
  background: #f4f4f4;
  padding: 20px;
  margin-bottom: 10px;
}
/deep/.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
