<template>
  <div class="ValuationItemEdit">
    <div class="pageTitle">估值问题项</div>
    <div class="content">
      <div class="tableBox">
        <el-table ref="eltable" :data="tableData" :header-cell-style="{background:'#f2f2f2'}"
          height="100%" stripe border v-loading="tableLoading">
          <template slot="empty">
            <img src="@/assets/img/table_noData.png" width="300" height="300" />
          </template>

          <el-table-column type="index" label="序号" align="center" width="50px"></el-table-column>
          <el-table-column prop="questionDescription" label="问题描述" align="left"></el-table-column>
          <el-table-column prop="questionExplain" label="问题说明" align="left"></el-table-column>
          <el-table-column prop="status" label="状态" align="left">
            <template slot-scope="scope">
              {{ valueToName(filterDictionary('0125'), scope.row.status) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" fixed="right" width="400">
            <template slot-scope="scope">
              <el-button type="success" icon="el-icon-edit" size="mini"
                @click="editHandle(scope.row)">编辑
              </el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini"
                @click="deleteQuestion(scope.row)">删除
              </el-button>
              <el-button type="primary" icon="el-icon-top-right" size="mini"
                @click="release(scope.row)">发布
              </el-button>
              <el-button type="info" icon="el-icon-bottom-right" size="mini"
                @click="downline(scope.row)">下线
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button type="primary" size="mini" icon="el-icon-plus" @click="addQuestion">添加问题项目
      </el-button>
    </div>
    <!-- 设置题目 -->
    <set-question ref="SetQuestion" :questionList="tableData" @refreshTable="refreshTable">
    </set-question>
  </div>
</template>

<script>
import SetQuestion from './ValuationItemEdit/SetQuestion.vue';
import api from '@/api/ValuationItemApi';

export default {
  name: 'ValuationItemEdit',
  components: {
    SetQuestion,
  },
  data() {
    return {
      tableData: [],
      tableLoading: false,
      id: '',
    };
  },
  created() {
    document.title = '估值问题项';
    const {
      id,
    } = this.$route.params;
    this.id = id;
    this.reqTableData(id);
  },
  methods: {
    async editHandle(row) {
      const rows = JSON.parse(JSON.stringify(row));
      this.$refs.SetQuestion.dialogVisible = true;
      this.$refs.SetQuestion.$refs.SetOption.questionId = rows.id;
      this.$refs.SetQuestion.dialogTitle = '编辑问题设置';
      const files = rows.attachmentInfoList.map(val => {
        val.attachmentId = val.id;
        return val.attachmentPath;
      });
      await rows.questionOptionList.forEach(val => {
        const cfiles = val.attachmentInfoList.map(cval => {
          cval.attachmentId = cval.id;
          return cval.attachmentPath;
        });
        val.files = cfiles.join(',');
        val.questionOptionId = val.id;
      });
      const formData = {
        ...rows,
        files: files.join(','),
        questionProjectId: rows.id,
      };
      this.$nextTick(() => {
        this.$refs.SetQuestion.formData = formData;
      });
    },
    addQuestion() {
      this.$refs.SetQuestion.dialogVisible = true;
      this.$refs.SetQuestion.dialogTitle = '添加问题设置';
    },
    deleteQuestion(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            api.deleteQuestionProject(row.id)
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    release(row) {
      this.$confirm('确定要<span class="dangerColor">发布</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在发布';
            api.modifyQuestionStatus({
              questionOptionId: row.id,
              status: 2,
            })
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('发布成功!');
                this.refreshTable();
              })
              .catch(err => {
                instance.confirmButtonLoading = false;
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    downline(row) {
      this.$confirm('确定要<span class="dangerColor">下线</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在下线';
            api.modifyQuestionStatus({
              questionOptionId: row.id,
              status: 3,
            })
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('下线成功!');
                this.refreshTable();
              })
              .catch(err => {
                instance.confirmButtonLoading = false;
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    refreshTable() {
      this.reqTableData(this.id);
    },
    reqTableData(id) {
      this.tableLoading = true;
      api.getQuestionProductList(id)
        .then(res => {
          this.tableData = res.result;
        })
        .catch(e => {
          console.log(e);
          this.$message.error(e.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};

</script>

<style lang="scss" scoped>
  .ValuationItemEdit {
    .pageTitle {
      position: fixed;
      top: 0;
      background: #ffffff;
      color: #333;
      padding: 0 20px;
      line-height: 50px;
      width: 100%;
      box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .content {
      padding: 50px;
      max-width: 1200px;
      margin: auto;

      .tableBox {
        height: calc(100vh - 300px);
        padding: 0;
        margin: 50px auto 20px;
      }
    }
  }

</style>
