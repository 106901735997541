import { postInfo } from './api';

export default {
  // 估值项目分页列表
  getAssessmentPageList(data) {
    return postInfo('/assessment/getAssessmentPageList', data);
  },
  // 删除估值
  deleteAssessmentParameter(data) {
    return postInfo('/assessment/deleteAssessmentParameter', data);
  },
  // 问题项目列表by{productId}
  getQuestionProductList(data) {
    return postInfo('/assessment/getQuestionProductList', data);
  },
  // 添加问题项目
  addQuestionProject(data) {
    return postInfo('/assessment/addQuestionProject', data);
  },
  // 修改问题项目
  modifyQuestionProject(data) {
    return postInfo('/assessment/modifyQuestionProject', data);
  },
  // 删除问题项目by{questionProjectId}
  deleteQuestionProject(data) {
    return postInfo('/assessment/deleteQuestionProject', data);
  },
  // 修改问题状态
  modifyQuestionStatus(data) {
    return postInfo('/assessment/modifyQuestionStatus', data);
  },
  // 删除问题选项by{questionOptionId}
  deleteQuestionOption(data) {
    return postInfo('/assessment/deleteQuestionOption', data);
  },
  cloneAssessment(data) {
    return postInfo('/assessment/cloneAssessment', data);
  },
};
