<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="800px"
      v-drag
      @close="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="140px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="基础价格影响值：" prop="impactBasePrice">
              <el-input-number
                v-model="formData.impactBasePrice"
                controls-position="right"
                :min="0"
                :step="10"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="questionLists.length">
            <el-form-item
              label="跳转到"
              label-width="100px"
              prop="redirectQuestionId"
            >
              <el-select v-model="formData.redirectQuestionId">
                <el-option
                  v-for="(item, index) in questionLists"
                  :key="index"
                  :label="item.questionDescription"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="questionLists.length">
            <el-form-item
              label="跳转类型"
              label-width="100px"
              prop="redirectType"
            >
              <el-select v-model="formData.redirectType">
                <el-option
                  v-for="(item, index) in filterDictionary('0121')"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="questionLists.length">
            <el-form-item label="如果选中此项：">
              <el-col :span="8">
                <el-form-item
                  label="则第"
                  label-width="50px"
                  prop="disableQuestionId"
                >
                  <el-select
                    v-model="formData.disableQuestionId"
                    @change="change"
                  >
                    <el-option
                      v-for="(citem, cindex) in questionLists"
                      :key="cindex"
                      :label="citem.questionDescription"
                      :value="citem.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="题的选项"
                  label-width="90px"
                  prop="disableOptionId"
                >
                  <el-select v-model="formData.disableOptionId" multiple>
                    <el-option
                      v-for="(citem, cindex) in disableOptions"
                      :key="`disableOptions-${cindex}`"
                      :label="citem.optionDescription"
                      :value="citem.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" style="padding-left: 20px">
                设置为不可选择
              </el-col>
            </el-form-item>
          </el-col>

          <el-col :span="24" class="desItem">
            <el-col :span="24">
              <el-col :span="22">
                <el-form-item label="说明内容：" prop="optionExplain">
                  <el-input
                    v-model="formData.optionExplain"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10 }"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-form-item label="图片：" prop="files">
                <l-upload-img
                  ref="LUploadImg"
                  :fileUrl="formData.files"
                  :folderName="'valueation_questions'"
                  @getFile="getFile"
                >
                </l-upload-img>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LUploadImg from '@/components/LUploadImg.vue';
import utils from '@/utils/utils';

export default {
  name: 'SetOption',
  components: {
    LUploadImg,
  },
  props: {
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '选项设置',
      loading: false,
      imgVisible: false,
      dialogImageUrl: '',
      quesIndex: 0,
      questionId: 0,
      formData: {
        impactBasePrice: '',
        redirectQuestionId: '',
        redirectType: '',
        optionExplain: '',
        files: '',
        attachmentInfoList: [],
        disableQuestionId: '',
        disableOptionId: [],
      },
      rules: {
        impactBasePrice: [
          {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
        ],
      },
      fileList: [],
    };
  },
  computed: {
    questionLists() {
      const arr = this.questionList.filter(
        val => `${val.id}` !== `${this.questionId}`,
      );
      return arr;
    },
    disableOptions() {
      let options = [];
      const target = this.questionLists.find(
        item => item.id === this.formData.disableQuestionId,
      );
      if (target) {
        options = target.questionOptionList;
      }

      return options;
    },
  },
  methods: {
    getFile(files) {
      const attachmentInfoList = files.map(val => {
        const obj = {
          attachmentName: val.name.split('/').reverse()[0],
          attachmentPath: val.name,
          attachmentType: 2,
        };
        return obj;
      });
      this.formData.attachmentInfoList = attachmentInfoList;

      const arr = files.map(val => val.name);
      this.formData.files = arr.join(',');
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          this.$emit('getOptionInfo', this.formData, this.quesIndex);
        }
      });
    },
    dialogClosed() {
      utils.clearFields(this.formData);
      this.$refs.elform.resetFields();
    },
    change() {
      const target = { ...this.formData };
      target.disableOptionId = [];
      this.formData = target;
    },
  },
};
</script>

<style lang="scss" scoped>
.desItem {
  background: #f4f4f4;
  padding: 20px 0;
  margin-bottom: 20px;
}

/deep/.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
